import React, { useState } from 'react';
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import './index.scss';
import { useFetchQuery, useUpdateMutation, useDeleteMutation, useShowInventoryQuery } from '../../services/inventory';
import { useSelector } from "react-redux";
import IconButton from '@mui/joy/IconButton';
import Pagination from '@mui/material/Pagination';
import PaginationItem from '@mui/material/PaginationItem';
import LinearProgress from '@mui/material/LinearProgress';
import SearchIcon from "@material-ui/icons/Search";
// import InventoryList from './InventoryList';
import InventoryList from '../InventoryC/InventoryList';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from "@material-ui/icons/Close";
import ArrowBackOutlinedIcon from '@mui/icons-material/ArrowBackOutlined';
import { Button, notification, Space } from 'antd';
import "antd/dist/antd.min.css";


function Box() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const location = useLocation();
  const { id } = useParams();
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const { register: register2, handleSubmit: handleSubmit2, formState: { errors: errors2 }, } = useForm();


  const page = new URLSearchParams(location.search).get('page') || 1
  const limit = new URLSearchParams(location.search).get('size') || 15

  const query = new URLSearchParams(location.search).get('query') || null
  const thisQuery = query ? `&query=${query}` : ''

  const history = useHistory();
  const { data: inventoryData, refetch, isLoading, isFetching } = useShowInventoryQuery({
    page: page,
    size: limit,
    userId: userId,
    userToken: userToken,
    admin: admin,
    query: query,
    userEmail: userEmail,
    id: id
  }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const inventories = inventoryData?.inventory;
  const pagination = inventoryData?.pagination;

  // const stockTimerId = useRef();
  // React.useEffect(() => {
  //   stockTimerId.current = setInterval(() => {
  //     refetch()
  //   }, 420000);
  //   return () => {
  //     clearInterval(stockTimerId.current);
  //   };
  // }, []);


  const [updateinventory, updateresult] = useUpdateMutation()
  const [deleteinventory, deleteresult] = useDeleteMutation()


  function getLoadingProgress(thisLoading, thisFetching, loading1, loading2) {
    if (thisLoading) {
      return true
    } else if (thisFetching) {
      return true
    } else if (loading1) {
      return true
    } else if (loading2) {
      return true
    } else {
      return false
    }
  }

  function searchOrder(data) {
    const result = data?.orderId;
    history.push(`?query=${result}`)
  }

  function clearSearch() {
    reset()
    history.push(`${window.location.pathname}`)
  }

  function addInventory(data) {
    const itemId = Number(data?.itemId);
    updateinventory({
      quantity: data?.quantity,
      id: itemId,
      userId: userId,
      userToken: userToken,
      admin: admin,
      box_id: id,
      add_from: data.add_from
    })
  }



  function removeQuantity(id, qty, pid) {
    updateinventory({
      product_id: pid,
      quantity: qty,
      id: id,
      userId: userId,
      userToken: userToken,
      admin: admin,
      removeQuantity: true
    })
  }

  function addQuantity(id, qty, pid) {
    updateinventory({
      product_id: pid,
      quantity: qty,
      id: id,
      userId: userId,
      userToken: userToken,
      admin: admin,
      addQuantity: true
    })
  }



  const [api, contextHolder] = notification.useNotification();

  React.useEffect(() => {
    if (updateresult?.isSuccess) {
      api['success']({
        message: 'successfully updated',
        showProgress: true,
        pauseOnHover: false,
        duration: 3
      });
    }
    if (updateresult?.isError) {
      api['error']({
        message: 'something went wrong',
        showProgress: true,
        pauseOnHover: false,
        duration: 3
      });
    }
  }, [updateresult]);

  const handleBack = () => {
    history.goBack();
  };


  return (
    <>
      {contextHolder}
      <div className="tabel_cards">

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="newFlexPagi">
            <div className="col-md-12">
              <div>
                <div className="storeCardB">
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap', flexWrap: 'wrap' }}>
                    <div style={{ display: 'flex', alignItems: 'center', flexWrap: 'wrap' }}>
                      <IconButton size='sm' variant='soft' onClick={handleBack}><ArrowBackOutlinedIcon /></IconButton>
                      <div style={{ margin: '0px 10px' }}>Showing {pagination?.totalCounts && pagination?.totalCounts} entries</div>
                    </div>
                    <div className="Order-Pagination">
                      <Pagination
                        style={{ width: '100%', textAlign: 'right', display: 'flex', justifyContent: 'flex-end', padding: '0px 0px 0px 0px' }}
                        page={parseInt(page)}
                        count={parseInt(pagination?.totalPages)}
                        color="primary"
                        variant="outlined"
                        renderItem={(item) => (
                          <PaginationItem
                            className='list-paginatiion-item'
                            component={Link}
                            to={{
                              pathname: `${window.location.pathname}`,
                              search: `?page=${parseInt(item.page)}${thisQuery}`
                            }}
                            {...item}
                          />
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="storeCardB">
              <div className="updateButton">
                <div className='overflow' style={{ display: 'flex', alignItems: 'center' }}>
                  <div style={{ position: 'relative' }}>
                    <form onSubmit={handleSubmit(searchOrder)} className="table_search_card">
                      <input
                        defaultValue={query}
                        type="text"
                        className="form-control table_search"
                        placeholder="Search for product..."
                        id="orderId"
                        aria-invalid={errors.orderId ? "true" : "false"}
                        {...register("orderId", { required: true })}
                      />
                      {!query &&
                        <IconButton size='sm' style={{ top: '3px', right: '5px', borderRadius: '100px' }} aria-haspopup="true" type="submit" className="table_search_icon">
                          <SearchIcon style={{ fontSize: 'inherit' }} />
                        </IconButton>
                      }
                      {query &&
                        <IconButton
                          size='sm'
                          style={{ top: '3px', right: '5px', borderRadius: '100px' }}
                          className="table_search_icon"
                          onClick={(() => clearSearch())}
                        >
                          <CloseIcon style={{ fontSize: 'inherit' }} />
                        </IconButton>}
                    </form>
                  </div>

                  <div style={{ display: 'flex', alignItems: 'center', margin: '0px 10px' }}>
                    <div style={{ position: 'relative' }}>
                      <form onSubmit={handleSubmit2(addInventory)} className="table_search_card">
                        <input
                          style={{ borderRadius: '4px', maxWidth: '150px', minWidth: '150px', borderTopRightRadius: 0, borderBottomRightRadius: 0, borderTopLeftRadius: '100px', borderBottomLeftRadius: '100px' }}
                          type="text"
                          className="form-control table_search"
                          placeholder="Item ID"
                          id="itemId"
                          aria-invalid={errors2.itemId ? "true" : "false"}
                          {...register2("itemId", { required: true })}
                        />
                        <select
                          style={{ borderRadius: '4px', maxWidth: '150px', minWidth: '150px', borderRadius: 0, borderRight: 'none', borderLeft: 'none' }}
                          className="form-control table_search"
                          id="add_from"
                          aria-invalid={errors2.add_from ? "true" : "false"}
                          {...register2("add_from", { required: false })}>
                          <option value={'item_id'} selected>Item ID</option>
                          <option value={'product_id'}>Product ID</option>
                        </select>
                        <input
                          style={{ borderRadius: '4px', maxWidth: '100px', minWidth: '100px', borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}
                          type="text"
                          className="form-control table_search"
                          placeholder="Qty."
                          id="quantity"
                          aria-invalid={errors2.quantity ? "true" : "false"}
                          {...register2("quantity", { required: false })}
                        />

                        <IconButton size='sm' variant='soft' style={{ top: '3px', right: '5px', borderRadius: '4px' }} aria-haspopup="true" type="submit" className="table_search_icon">
                          <AddIcon />
                        </IconButton>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="table_search_card">

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row" style={{ margin: 0, padding: 0, position: 'relative' }}>
          <div style={{ padding: 0, width: '100%' }}>
            <div className="tabelOverflow" style={{ position: 'relative' }}>
              {getLoadingProgress(isLoading, isFetching, updateresult?.isLoading, deleteresult?.isLoading) &&
                <div className='loading-progress-bg' style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 222, height: '100%', background: '#ffffff82' }}>
                  <LinearProgress style={{ height: '2px' }} />
                </div>
              }

              <table className="table" style={{ margin: 0 }}>
                <thead>
                  <tr style={{ position: 'relative' }}>
                    <th>ID</th>
                    <th>Box</th>
                    <th>Image</th>
                    <th>Title</th>
                    <th>Price</th>
                    <th>Qty.</th>
                    <th>Condition</th>
                    <th>Variants</th>
                    <th className="sticky-column" style={{ textAlign: 'right', position: 'relative' }}>
                      Action
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <InventoryList inventoryData={inventoryData} deleteinventory={deleteinventory} deleteresult={deleteresult} addQuantity={addQuantity} removeQuantity={removeQuantity} updateresult={updateresult} />
                </tbody>
              </table>
            </div>
            {inventories?.length === 0 &&
              <h5 className="data_not-found">No data available in table</h5>
            }
            {inventoryData?.error?.originalStatus === 403 &&
              <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                <div className='alert alert-danger' style={{ background: '#fff0', border: 'none' }}>{inventoryData?.error?.data}</div>
              </div>
            }
          </div>
        </div>
        <br /><br />
      </div>
    </>
  );
}

export default Box;
