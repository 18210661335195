import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { createEntityAdapter } from '@reduxjs/toolkit'

export const refundApi = createApi({
  reducerPath: 'refundApi',
  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['Refund'],
  keepUnusedDataFor: 86400000,
  refetchOnMountOrArgChange: 1,

  endpoints: (builder) => ({


    getAllRefunds: builder.query({
      query: (data) => {
        return {
          url: `refunds?page=${data.page}&size=${data.size}${data.search ? `&search=${data.search}` : ''}`,
          method: 'GET',
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.userRole,
            'Accept': 'application/json',
            'Content-Type': 'application/json',
          },
        }
      },
      keepUnusedDataFor: 86400000,
      providesTags: ['Refund'],
    }),


    show: builder.query({
      query: (data) => ({
        url: `orders/${data.id}`,
        method: 'GET',
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.admin,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),
      // keepUnusedDataFor: 1,
      providesTags: ['Refund'],
    }),



    fetchRefund: builder.query({
      query: (data) => ({
        url: `refund/${data.type}/get/${data.id}`,
        method: 'GET',
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.admin,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),
      // keepUnusedDataFor: 1,
      providesTags: ['Refund'],
    }),


    searchRefund: builder.query({
      query: (data) => ({
        url: `refund/stripe/${data.id}`,
        method: 'GET',
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.admin,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),
      // keepUnusedDataFor: 1,
      providesTags: ['Refund'],
    }),




    createRefund: builder.mutation({
      query: (data) => ({
        url: `refund/${data.type}/${data.order_number}`,
        method: 'POST',
        body: data,
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.admin,
          'Accept': 'application/json',
          'Content-type': 'application/json; charset=UTF-8',
        }
      }),
      // keepUnusedDataFor: 1,
      invalidatesTags: ['Refund'],
    }),


  }),

})

export const { useShowQuery, useCreateRefundMutation, useFetchRefundQuery, useSearchRefundQuery, useGetAllRefundsQuery } = refundApi