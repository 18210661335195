import React, { useState, useEffect, Fragment } from 'react';
import { useForm } from "react-hook-form";
import './index.scss';
import { useSelector } from "react-redux";
import 'react-phone-number-input/style.css'
import Button from '@mui/joy/Button';
import { useCreateOrderMutation } from '../../services/orders';
function CreateOrderC() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);
  const { register, handleSubmit, setValue, reset, formState: { errors } } = useForm();
  const [createorder, createresult] = useCreateOrderMutation()

  async function createshippingaddress(data) {
    createorder({
      data: data,
      userId: userId,
      userToken: userToken,
      admin: admin,
    })
  }

  useEffect(() => {
    if (createresult?.isSuccess) {
      reset()
    }
  }, [createresult]);


  const [inputFields, setInputFields] = useState([{ product_id: "", pid: "", asin: "", title: "", image: "", product_country: "", price: "", quantity: "" }]);
  const handleAddField = () => {
    setInputFields([...inputFields, { product_id: "", pid: "", asin: "", title: "", image: "", product_country: "", price: "", quantity: "" }]);
  };
  const handleRemoveField = (index) => {
    const updatedFields = inputFields.filter((_, i) => i !== index);
    setInputFields(updatedFields);
  };
  const handleInputChange = (index, field, value) => {
    const updatedFields = [...inputFields];
    updatedFields[index][field] = value;
    setInputFields(updatedFields);
  };


  useEffect(() => {
    setValue('products', inputFields);
  }, [setValue, inputFields]);


  return (
    <>
      <div className="tabel_cards">
        <div className="row" style={{ margin: 0, padding: 0, position: 'relative' }}>
          <div style={{ padding: 0, width: '100%' }}>

            <form onSubmit={handleSubmit(createshippingaddress)}>
              <div className="row" style={{ margin: 0, padding: 0, position: 'relative' }}>

                <div className="col-md-7">
                  <div style={{ background: '#fff', border: '1px solid #eee', padding: '10px', marginTop: '20px', borderRadius: '6px' }}>
                    <div className="row">
                      <div className="col-md-12">
                        <div style={{ padding: '10px 0px 10px 0px', fontWeight: 500 }}>Order Details</div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <div className="form-group create-no">
                          <label>Domain</label>
                          <select id="domain"
                            aria-invalid={errors.domain ? "true" : "false"}
                            {...register('domain', { required: true })} className="form-control">
                            <option disabled selected value={''}>Select</option>
                            <option value={'thwifty'}>Thwifty</option>
                            <option value={'goshopright'}>goshopright</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group create-no">
                          <label>Currency</label>
                          <select id="currency"
                            aria-invalid={errors.currency ? "true" : "false"}
                            {...register('currency', { required: true })} className="form-control">
                            <option disabled selected value={''}>Select</option>
                            <option value={'AED'}>AED</option>
                            <option value={'SAR'}>SAR</option>
                            <option value={'GBP'}>GBP</option>
                            <option value={'KWD'}>KWD</option>
                            <option value={'BHD'}>BHD</option>
                            <option value={'QAR'}>QAR</option>
                            <option value={'OMR'}>OMR</option>
                            <option value={'INR'}>INR</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-4">
                        <div className="form-group create-no">
                          <label>Shipping Price</label>
                          <input
                            type="number"
                            className="form-control"
                            id="shipping"
                            aria-invalid={errors.shipping ? "true" : "false"}
                            {...register('shipping', { required: true })} />
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-12">
                        <div style={{ padding: '10px 0px 10px 0px', fontWeight: 500 }}>Shipping Address</div>
                      </div>
                    </div>

                    <div className="row">

                      <div className="col-md-5">
                        <div className="form-group create-no">
                          <label>Name</label>
                          <input
                            type="text"
                            className="form-control"
                            id="name"
                            aria-invalid={errors.name ? "true" : "false"}
                            {...register('name', { required: true })} />
                        </div>
                      </div>
                      <div className="col-md-7">
                        <div className="form-group create-no">
                          <label>Email</label>
                          <input
                            type="email"
                            className="form-control"
                            id="email"
                            aria-invalid={errors.email ? "true" : "false"}
                            {...register('email', { required: true })} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group create-no">
                          <label>Address</label>
                          <input
                            type="text"
                            className="form-control"
                            id="address"
                            aria-invalid={errors.address ? "true" : "false"}
                            {...register('address', { required: true })} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group create-no">
                          <label>Street</label>
                          <input
                            type="text"
                            className="form-control"
                            id="street"
                            aria-invalid={errors.street ? "true" : "false"}
                            {...register('street', { required: true })} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group create-no">
                          <label>City</label>
                          <input
                            type="text"
                            className="form-control"
                            id="city"
                            aria-invalid={errors.city ? "true" : "false"}
                            {...register('city', { required: true })} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group create-no">
                          <label>Country</label>
                          <select id="Country"
                            aria-invalid={errors.Country ? "true" : "false"}
                            {...register('Country', { required: true })} className="form-control">
                            <option disabled selected value={''}>Select</option>
                            <option value={'AE'}>United Arab Emirates</option>
                            <option value={'GB'}>United Kingdom</option>
                            <option value={'SA'}>Saudi Arabia</option>
                            <option value={'OM'}>Oman</option>
                            <option value={'KW'}>Kuwait</option>
                            <option value={'BH'}>Bahrain</option>
                            <option value={'QA'}>Qatar</option>
                            <option value={'IN'}>India</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group create-no">
                          <label>Phone</label>
                          <input
                            type="number"
                            className="form-control"
                            id="phone"
                            aria-invalid={errors.phone ? "true" : "false"}
                            {...register('phone', { required: true })} />
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group create-no">
                          <label>Zip Code</label>
                          <input
                            type="number"
                            className="form-control"
                            id="zip_code"
                            aria-invalid={errors.zip_code ? "true" : "false"}
                            {...register('zip_code', { required: false })} />
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-12">
                            <div style={{ padding: '10px 0px 10px 0px', fontWeight: 500 }}>Product Details</div>
                          </div>
                        </div>

                        <div className="col-md-12" style={{ marginBottom: '10px', marginTop: '10px', padding: 0 }}>
                          <div style={{ padding: '0px', borderRadius: '10px' }}>
                            {inputFields.map((field, index) => (
                              <div className="row" key={index} style={{ marginBottom: "16px", margin: 0, position: 'relative', display: 'flex', alignItems: 'center', flexWrap: 'wrap', width: '100%' }}>

                                {index === 0 &&
                                  <div className="col-md-12" style={{ padding: 0 }}>
                                    <div className="form-group create-no">
                                      <Button style={{ fontWeight: 400 }} variant='outlined' color='success' size='sm' onClick={handleAddField}>
                                        Add More Product
                                      </Button>
                                    </div>
                                  </div>
                                }
                                {index > 0 &&
                                  <div className="col-md-12" style={{ padding: 0, marginTop: '10px' }}>
                                    <div className="form-group create-no">
                                      <Button style={{ fontWeight: 400 }} variant='outlined' color='danger' size='sm' onClick={() => handleRemoveField(index)}>
                                        Remove
                                      </Button>
                                    </div>
                                  </div>
                                }
                                <div className="col-md-12" style={{ padding: 0 }}>
                                  <div style={{ background: '#f9f9f9', border: '1px solid #eee', padding: '10px', marginTop: '5px', borderRadius: '6px' }}>
                                    <div className="row">
                                      <div className="col-md-4">
                                        <div className="form-group create-no">
                                          <label>Product ID</label>
                                          <input
                                            className="form-control"
                                            type="number"
                                            value={field.product_id}
                                            onChange={(e) => handleInputChange(index, "product_id", e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group create-no">
                                          <label>PID</label>
                                          <input
                                            className="form-control"
                                            type="text"
                                            value={field.pid}
                                            onChange={(e) => handleInputChange(index, "pid", e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group create-no">
                                          <label>ASIN</label>
                                          <input
                                            className="form-control"
                                            type="text"
                                            value={field.asin}
                                            onChange={(e) => handleInputChange(index, "asin", e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-12">
                                        <div className="form-group create-no">
                                          <label>Title</label>
                                          <input
                                            className="form-control"
                                            type="text"
                                            value={field.title}
                                            onChange={(e) => handleInputChange(index, "title", e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-12">
                                        <div className="form-group create-no">
                                          <label>Image</label>
                                          <input
                                            className="form-control"
                                            type="text"
                                            value={field.image}
                                            onChange={(e) => handleInputChange(index, "image", e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group create-no">
                                          <label>Product Country</label>
                                          <select
                                            value={field.product_country}
                                            onChange={(e) => handleInputChange(index, "product_country", e.target.value)}
                                            className="form-control">
                                            <option selected disabled value={''}>Select</option>
                                            <option value={'AE'}>United Arab Emirates</option>
                                            <option value={'US'}>United State</option>
                                            <option value={'GB'}>United Kingdom</option>
                                            <option value={'IN'}>India</option>
                                          </select>
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group create-no">
                                          <label>Product Price</label>
                                          <input
                                            className="form-control"
                                            type="number"
                                            value={field.price}
                                            onChange={(e) => handleInputChange(index, "price", e.target.value)}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <div className="form-group create-no">
                                          <label>Quantity</label>
                                          <input
                                            className="form-control"
                                            type="number"
                                            value={field.quantity}
                                            onChange={(e) => handleInputChange(index, "quantity", e.target.value)}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 Header-signin-button" style={{ marginTop: '10px', position: 'sticky', bottom: 0, padding: '5px' }}>
                        <div className="form-group" style={{ margin: 0, background: '#fcfcfc', padding: '10px', borderTop: '1px solid #eee' }}>
                          {createresult?.data?.order_number ?
                            <div style={{ fontSize: '1rem' }}>Order has created:&nbsp; <strong>{createresult?.data?.order_number}</strong></div>
                            :
                            <Button
                              disabled={createresult?.isLoading}
                              variant='soft'
                              size='sm'
                              style={{ width: '100%', height: '45px' }}
                              type="submit">
                              {createresult?.isLoading ? 'Creating...' : 'Submit'}
                            </Button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

export default CreateOrderC;
