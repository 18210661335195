import React, { useState, useEffect, Fragment } from 'react';
import './index.scss';
import { useFetchRefundQuery } from '../../services/refund';
import { useSelector } from "react-redux";
import LinearProgress from '@mui/material/LinearProgress';

function PaybyOP({ orderInfo, setUpdateStatus }) {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);

  const orderData = orderInfo?.data?.order

  const refundInfo = useFetchRefundQuery({ id: orderData?.order_number, type: 'payby', userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const refundData = refundInfo?.data?.refund?.body


  // useEffect(() => {
  //   if (refundInfo?.isSuccess) {
  //     setUpdateStatus(true)
  //   }
  // }, [refundInfo]);

  function getLinearProgress() {
    return (
      <div style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 111, height: '100%', background: '#ffffff82' }}>
        <LinearProgress style={{ height: '2px' }} />
      </div>
    )
  }


  return (
    <>
      {/* {refundInfo?.isLoading &&
        getLinearProgress()
      } */}

      {!refundInfo?.isLoading &&
        <>
          {refundData &&
            <div style={{ background: '#fcfcfc', fontSize: '13px', borderBottom: '1px solid #eee', paddingBottom: '5px', marginBottom: '5px' }}>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '5px' }}>
                <div>Status: &nbsp;</div>
                <div>{refundData?.refundOrder?.status}</div>
              </div>
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '5px' }}>
                <div>Amount: &nbsp;</div>
                <div> {refundData?.refundOrder?.amount?.currency}&nbsp;{refundData?.refundOrder?.amount?.amount}</div>
              </div>
            </div>
          }
        </>
      }
      <br />
    </>
  );
}

export default PaybyOP;
