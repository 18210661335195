import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation } from "react-router-dom";
import './index.scss';
import { useDispatch, useSelector } from 'react-redux'
import { useGetOrdersCSVQuery, useCreateOrdersCSVMutation } from '../../services/media';
import Button from '@mui/joy/Button';
import Loader from '../../Helper/Loader';
import LinearProgress from '@mui/material/LinearProgress';
import moment from 'moment'
function GenerateCSV() {
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode } = useSelector((state) => state.auth);
  const dispatch = useDispatch()
  const history = useHistory();
  const location = useLocation();
  const [createcsv, createcsvresult] = useCreateOrdersCSVMutation()

  const { data: responseInfo, error, isLoading, isFetching, refetch } = useGetOrdersCSVQuery({ userId: userId, userToken: userToken, userRole: userRole },
    { count: 1 },
    { refetchOnMountOrArgChange: true })
  const cachedData = responseInfo?.entities?.undefined?.orderscsv;

  function geyStatus(status) {
    if (status === 'completed') {
      return 'badge badge-success'
    } else if (status === 'in_progress') {
      return 'badge badge-warning'
    } else {
      return 'badge badge-danger'
    }
  }

  useEffect(() => {
    if (createcsvresult?.isSuccess) {
      refetch()
    }
  }, [createcsvresult]);

  function buttonDisabled(loading, status) {
    if (loading) {
      return true
    } else if (status === 'in_progress') {
      return true
    } else {
      return false
    }
  }


  function getPageLoading(loading, fetching) {
    if (loading) {
      return true
    } else if (fetching) {
      return true
    } else {
      return false
    }
  }


  const currentTime = moment().utc('Asia/Dubai').format('YYYY-MM-DD HH:mm'); // Dubai time offset
  const thisTime = moment(cachedData?.date).utc('Asia/Dubai').format('YYYY-MM-DD HH:mm');
  const diffInMinutes = moment(currentTime).diff(moment(thisTime), 'minutes');

  return (
    <>
      <div className="tabel_cards category-page">

        <div className="row" style={{ margin: 0, padding: 0 }}>
          <div className="col-md-12" style={{ padding: 0 }}>
            <div className="storeCardB">
              <div className="updateButton" style={{ border: 'none' }}>
                <div className="table_search_card">
                  <div>
                    <Button
                      disabled={buttonDisabled(createcsvresult?.isLoading, cachedData?.status)}
                      style={{ fontWeight: 400 }}
                      variant="outlined"
                      size="sm"
                      onClick={() => createcsv({ userId: userId, userToken: userToken, userRole: userRole })}>
                      {buttonDisabled(createcsvresult?.isLoading, cachedData?.status) ? "Generating" : 'Generate New CSV File'}
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {getPageLoading(isLoading, isFetching) &&
          <LinearProgress style={{ height: '2px' }} />
        }

        <br />
        {!isLoading &&
          <>
            {!cachedData &&
              <div className="row" style={{ margin: 0, padding: 0 }}>
                <div className="col-md-12 media-card">
                  <h6 className='alert alert-danger' style={{ border: 'none', background: '#fff0', padding: '0px' }}>File Not Found</h6>
                </div>
              </div>
            }
          </>
        }

        {cachedData &&
          <>
            <div className="row" style={{ margin: 0, padding: 0 }}>
              <div className="col-md-12 media-card">
                {buttonDisabled(createcsvresult?.isLoading, cachedData?.status) ?
                  <h5>File Generating</h5> :
                  <>
                    <h5>Last File Generated</h5>
                    <ul>
                      <li><small><i>The link will be active for 10 minutes.</i></small></li>
                      <li><small><i>The data is generated for the past 7 days, including today.</i></small></li>
                    </ul>
                  </>
                }
              </div>
            </div>
            <div className="row" style={{ margin: 0, padding: 0 }}>
              <div className="col-md-12" style={{ padding: 0 }}>
                <div className="tabelOverflow row" style={{ margin: 0, padding: 0, position: 'relative' }}>
                  <div className="col-md-5 media-card">
                    File is Ready to Download:
                  </div>
                  <div className="col-md-5 media-card">
                    <a
                      style={{ fontSize: '15px' }}
                      disabled={buttonDisabled(createcsvresult?.isLoading, cachedData?.status)}
                      href={cachedData?.url}
                      target='_blank'
                      download>
                      Click here to download
                    </a>
                    {!buttonDisabled(createcsvresult?.isLoading, cachedData?.status) &&
                      <>
                        {cachedData?.date &&
                          <>
                            {diffInMinutes > 10 ?
                              <small style={{ margin: '0px 10px', background: '#fff0', border: 'none', padding: '0px' }} className='alert alert-danger'><i>Expired</i></small> :
                              <small style={{ margin: '0px 10px', background: '#fff0', border: 'none', padding: '0px' }} className='alert alert-success'><i>Active</i></small>
                            }
                          </>
                        }
                      </>
                    }
                  </div>
                  <div className="col-md-5 media-card">
                    Status:
                  </div>
                  <div className="col-md-5 media-card">
                    <div style={{ display: 'flex', alignItems: 'center' }}>
                      <div style={{ textTransform: 'capitalize' }} className={geyStatus(cachedData?.status)}>{cachedData?.status?.replace('_', ' ')}</div>
                      {buttonDisabled(createcsvresult?.isLoading, cachedData?.status) &&
                        <div style={{ margin: '0px 10px' }}><Loader /></div>
                      }
                    </div>
                  </div>
                  <div className="col-md-5 media-card">
                    Date:
                  </div>
                  <div className="col-md-5 media-card">
                    {moment.utc(cachedData?.date).format('lll')}
                  </div>
                  <div className="col-md-5 media-card">
                    Created By:
                  </div>
                  <div className="col-md-5 media-card">
                    {cachedData?.admin_email}
                  </div>
                </div>
              </div>
            </div>
          </>
        }
        {error?.originalStatus === 403 &&
          <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
            <div className='alert alert-danger' style={{ background: '#fff0', border: 'none' }}>{error?.data}</div>
          </div>
        }

      </div>
    </>
  );
}

export default GenerateCSV;
