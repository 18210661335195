import React from 'react';
import './index.scss';
import { useFetchRefundQuery } from '../../services/refund';
import { useSelector } from "react-redux";
import LinearProgress from '@mui/material/LinearProgress';

function Stripe({ orderInfo }) {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);

  const orderData = orderInfo?.data?.order

  const refundInfo = useFetchRefundQuery({ id: orderData?.piid, type: 'stripe', userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const refundData = refundInfo?.data
  
  function getLinearProgress() {
    return (
      <div style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 111, height: '100%', background: '#ffffff82' }}>
        <LinearProgress style={{ height: '2px' }} />
      </div>
    )
  }

  const ThreeDecimal = ['bhd', 'jod', 'kwd', 'omr', 'tnd']
  const ZeroDecimal = ['bif', 'clp', 'djf', 'gnf', 'jpy', 'kmf', 'krw', 'mga', 'pyg', 'rwf', 'ugx', 'vnd', 'vuv', 'xaf', 'xof', 'xpf']

  function fetchCurrency(amount, currency) {
    if (ThreeDecimal?.includes(currency)) {
      return Number(amount) / 1000
    } else if (ZeroDecimal?.includes(currency)) {
      return Number(amount)
    } else {
      return Number(amount) / 100
    }
  }

  return (
    <>
      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="col-md-12 list-card-op" style={{ position: 'relative' }}>
          <div className='details-card' style={{ position: 'relative', padding: '0px', border: 'none' }}>
            <div className="tabelOverflow details-card" style={{ position: 'relative', overflow: 'hidden', whiteSpace: 'normal', border: '1px solid #eee' }}>
              {refundInfo?.isLoading &&
                getLinearProgress()
              }
              <div className="row">
                <div className="col-md-12">
                  <h5 style={{ textAlign: 'left' }}>Refund details for Order {orderData?.order_number}</h5>
                </div>
              </div>

              <br />
              {!refundInfo?.isLoading &&
                <>
                  {refundData ?
                    <>
                      {refundData?.data?.data?.map((item, i) =>
                        <div key={i} style={{ background: '#fcfcfc', borderBottom: '2px solid #adadad', paddingBottom: '10px', marginBottom: '10px' }}>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '10px' }}>
                            <div>Reason: &nbsp;</div>
                            <div>{item?.reason}</div>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '10px' }}>
                            <div>Status: &nbsp;</div>
                            <div>{item?.status}</div>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '10px' }}>
                            <div>Amount: &nbsp;</div>
                            <div>{fetchCurrency(item?.amount, item?.currency)}</div>
                          </div>
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '10px' }}>
                            <div>Currency: &nbsp;</div>
                            <div>{item?.currency}</div>
                          </div>
                        </div>
                      )}
                    </> :
                    <div className='alert alert-info' style={{ textAlign: 'center' }}> No refund initiated</div>
                  }
                </>
              }
            </div>
          </div>
        </div>
      </div >
      <br />
      <br />
    </>
  );
}

export default Stripe;
