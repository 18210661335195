import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap'
import IconButton from '@mui/material/IconButton';
import Button from '@mui/material/Button';
import CloseIcon from '@mui/icons-material/Close';
import Checkbox from '@mui/joy/Checkbox';
import Payby from '../Refund/Payby';
import Stripe from '../Refund/Stripe';
import Tap from '../Refund/Tap';
import LinearProgress from '@mui/material/LinearProgress';
function Refund({ getRefundPopup, cancelRefundPopup, orderInfo }) {

  return (
    <>
      <Modal className="refund-modal" show={getRefundPopup} onHide={cancelRefundPopup} centered animation={false}>
        <Modal.Body>
          <IconButton
            style={{ cursor: 'pointer', position: 'absolute', right: '5px', top: '5px', zIndex: 111 }}
            onClick={cancelRefundPopup}
          >
            <CloseIcon className="Header-close-modal-icon" />
          </IconButton>

          <div className="row">
            <div className="col-md-12">
              <h5 style={{ textAlign: 'center', padding: '10px' }}>Refund</h5>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              {orderInfo?.data?.order?.payment_type === 'tap' ?
                <Tap orderInfo={orderInfo}
                /> : ''
              }

              {orderInfo?.data?.order?.payment_type === 'tap_tabby' ?
                <Tap orderInfo={orderInfo}
                /> : ''
              }

              {orderInfo?.data?.order?.payment_type === 'payby' ?
                <Payby orderInfo={orderInfo}
                /> : ''
              }
              {orderInfo?.data?.order?.payment_type === 'payby_apple' ?
                <Payby orderInfo={orderInfo}
                /> : ''
              }
              {orderInfo?.data?.order?.payment_type === 'apple_pay' ?
                <Stripe orderInfo={orderInfo}
                /> : ''
              }

              {orderInfo?.data?.order?.payment_type === 'card' ?
                <Stripe orderInfo={orderInfo}
                /> : ''
              }

            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )

}

export default Refund;
