import React, { useState, useEffect, Fragment } from 'react';
import CreateOrderC from '../../components/CreateOrderC';


function CreateOrder() {
  return (
    <div className="tabel_cards">
      <CreateOrderC />
    </div>

  );
}

export default CreateOrder;
