import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import { createEntityAdapter } from '@reduxjs/toolkit'
import io from "socket.io-client";
const messagesAdapter = createEntityAdapter()

const access = "full_access"

export const orderItemsApi = createApi({

  reducerPath: 'orderItemsApi',

  baseQuery: fetchBaseQuery({
    baseUrl: `${process.env.REACT_APP_API_CONNECT}/`,
  }),

  tagTypes: ['OrderItems'],
  keepUnusedDataFor: 86400000,
  refetchOnMountOrArgChange: 1,

  endpoints: (builder) => ({

    fetch: builder.query({
      query: (data) => ({
        url: `order_items?page=${data.page}&size=${data.size}${data.shipping_status ? `&shipping_status=${data.shipping_status}` : ''}${data.query ? `&query=${data.query}` : ''}${data.filter ? `&filter=${data.filter}` : ''}${data.assignee ? `&assignee=${data.assignee}` : ''}${data.ordered ? `&ordered=${data.ordered}` : ''}${data.payment_status ? `&payment_status=${data.payment_status}` : ''}${data.date_range ? `&date_range=${data.date_range}` : ''}`,
        method: 'GET',
        headers: {
          'Access-Key': localStorage.getItem('accessKey'),
          'X-User-Token': data.userToken,
          'X-User-Id': data.userId,
          'X-User-Role': data.admin,
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        },

      }),

      transformResponse(response) {
        return messagesAdapter.addMany(
          messagesAdapter.getInitialState(),
          response
        )
      },

      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        const socket = io(process.env.REACT_APP_API_CONNECT, {
          reconnectionDelay: 1000,
          reconnection: true,
          reconnectionAttemps: 10,
          transports: ["websocket"],
          agent: false,
          upgrade: false,
          rejectUnauthorized: false,
        });
        try {
          await cacheDataLoaded;
          const argData = {
            page: parseInt(arg.page),
            size: parseInt(arg.size),
          }
          const listener = (event) => {
            if (JSON.stringify(argData) !== JSON.stringify(event.channel)) return
            
            const values = event.email?.split(',');
            const localDomain = localStorage.getItem('domain')
            const adminDomain = process.env.REACT_APP_API_DOMAIN
           
            if (event.adminAccess && !JSON.parse(localStorage.getItem('adminAccess'))?.access?.includes(access)) {
              if (values?.includes(arg.userEmail)) {
                if (localDomain === adminDomain) {
                  updateCachedData((draft) => {
                    messagesAdapter.upsertOne(draft, event.dataa)
                  })
                } else {
                  updateCachedData((draft) => {
                    messagesAdapter.upsertOne(draft, event?.domain === localDomain ? event.data : '')
                  })
                }
              }
            }

            if (!event.adminAccess && JSON.parse(localStorage.getItem('adminAccess'))?.access?.includes(access)) {
              if (localDomain === adminDomain) {
                updateCachedData((draft) => {
                  messagesAdapter.upsertOne(draft, event.dataa)
                })
              } else {
                updateCachedData((draft) => {
                  messagesAdapter.upsertOne(draft, event?.domain === localDomain ? event.data : '')
                })
              }
            }

          }
          socket.addEventListener('order_items', listener)
        } catch (err) { }
        await cacheEntryRemoved;
        socket.close();
      },

      keepUnusedDataFor: 86400000,
      providesTags: ['OrderItems'],
    }),

    updateStates: builder.mutation({
      query: (data) => {
        return {
          url: `order_items/${data.id}`,
          method: 'PUT',
          body: data,
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          }
        }
      },
      // keepUnusedDataFor: 1,
      invalidatesTags: ['OrderItems'],
    }),

    delayedCount: builder.query({
      query: (data) => {
        return {
          url: `order_items/delayed/count`,
          method: 'GET',
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      keepUnusedDataFor: 86400000,
      providesTags: ['OrderItems'],
    }),

    processingCount: builder.query({
      query: (data) => {
        return {
          url: `order_items/processing/count`,
          method: 'GET',
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      keepUnusedDataFor: 86400000,
      providesTags: ['OrderItems'],
    }),

    deliveredCount: builder.query({
      query: (data) => {
        return {
          url: `order_items/delivered/count`,
          method: 'GET',
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      keepUnusedDataFor: 86400000,
      providesTags: ['OrderItems'],
    }),
    
    delayed: builder.query({
      query: (data) => {
        return {
          url: `order_items/delayed?page=${data.page}&size=${data.size}`,
          method: 'GET',
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      keepUnusedDataFor: 86400000,
      providesTags: ['OrderItems'],
    }),

    shipments: builder.query({
      query: (data) => {
        return {
          url: `order_items/shipments?page=${data.page}&size=${data.size}${data.search ? `&search=${data.search}` : ''}`,
          method: 'GET',
          headers: {
            'Access-Key': localStorage.getItem('accessKey'),
            'X-User-Token': data.userToken,
            'X-User-Id': data.userId,
            'X-User-Role': data.admin,
            'Accept': 'application/json',
            'Content-type': 'application/json; charset=UTF-8',
          },
        }
      },
      keepUnusedDataFor: 86400000,
      providesTags: ['OrderItems'],
    }),
    


  }),

})

export const { useFetchQuery, useUpdateStatesMutation, useDelayedCountQuery, useProcessingCountQuery, useDeliveredCountQuery, useDelayedQuery, useShipmentsQuery } = orderItemsApi