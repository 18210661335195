import React from 'react';
import './index.scss';
import { useFetchRefundQuery } from '../../services/refund';
import { useSelector } from "react-redux";
import LinearProgress from '@mui/material/LinearProgress';

function StripeOP({ orderInfo }) {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);

  const orderData = orderInfo?.data?.order

  const refundInfo = useFetchRefundQuery({ id: orderData?.piid, type: 'stripe', userId: userId, userToken: userToken, admin: admin }, { count: 1 }, { refetchOnMountOrArgChange: true })
  const refundData = refundInfo?.data

  function getLinearProgress() {
    return (
      <div style={{ position: 'absolute', top: 0, right: 0, left: 0, zIndex: 111, height: '100%', background: '#ffffff82' }}>
        <LinearProgress style={{ height: '2px' }} />
      </div>
    )
  }

  const ThreeDecimal = ['bhd', 'jod', 'kwd', 'omr', 'tnd']
  const ZeroDecimal = ['bif', 'clp', 'djf', 'gnf', 'jpy', 'kmf', 'krw', 'mga', 'pyg', 'rwf', 'ugx', 'vnd', 'vuv', 'xaf', 'xof', 'xpf']

  function fetchCurrency(amount, currency) {
    if (ThreeDecimal?.includes(currency)) {
      return Number(amount) / 1000
    } else if (ZeroDecimal?.includes(currency)) {
      return Number(amount)
    } else {
      return Number(amount) / 100
    }
  }

  return (
    <>
      {/* {refundInfo?.isLoading &&
        getLinearProgress()
      } */}
      {!refundInfo?.isLoading &&
        <>
          {refundData &&
            <>
              {refundData?.data?.data?.map((item, i) =>
                <div key={i} style={{ background: '#fcfcfc', fontSize: '13px', borderBottom: '2px solid #adadad', paddingBottom: '5px', marginBottom: '5px' }}>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '5px' }}>
                    <div>Status: &nbsp;</div>
                    <div>{item?.status}</div>
                  </div>
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexWrap: 'wrap', paddingBottom: '5px' }}>
                    <div>Amount: &nbsp;</div>
                    <div>{item?.currency}&nbsp;{fetchCurrency(item?.amount, item?.currency)}</div>
                  </div>
                </div>
              )}
            </>
          }
        </>
      }
      <br />
    </>
  );
}

export default StripeOP;
