import React, { useState, useEffect, Fragment } from 'react';
import { Modal } from 'react-bootstrap'
import PaybyOP from '../Refunds/PaybyOP';
import StripeOP from '../Refunds/StripeOP';
import TapOP from '../Refunds/TapOP';

function RefundStatus({ orderInfo }) {

  return (
    <>
      <div className="row">
        <div className="col-md-12">
          {orderInfo?.data?.order?.payment_type === 'tap' ?
            <TapOP orderInfo={orderInfo}
            /> : ''
          }

          {orderInfo?.data?.order?.payment_type === 'tap_tabby' ?
            <TapOP orderInfo={orderInfo}
            /> : ''
          }

          {orderInfo?.data?.order?.payment_type === 'payby' ?
            <PaybyOP orderInfo={orderInfo}
            /> : ''
          }
          {orderInfo?.data?.order?.payment_type === 'payby_apple' ?
            <PaybyOP orderInfo={orderInfo}
            /> : ''
          }
          {orderInfo?.data?.order?.payment_type === 'apple_pay' ?
            <StripeOP orderInfo={orderInfo}
            /> : ''
          }

          {orderInfo?.data?.order?.payment_type === 'card' ?
            <StripeOP orderInfo={orderInfo}
            /> : ''
          }
        </div>
      </div>

    </>
  )

}

export default RefundStatus;
