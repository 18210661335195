import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query';
import { productsApi } from '../services/products';
import { ordersApi } from '../services/orders';
import { orderItemsApi } from '../services/orderItems';
import { UsersApi } from '../services/users';
import { teamApi } from '../services/team';
import { MembershipApi } from '../services/membership';
import { loginApi } from '../services/login';
import { mediaApi } from '../services/media';
import { countriesApi } from '../services/countries';
import { routeOneApi } from '../services/route_one';
import { googleMApi } from '../services/google_merchant';
import { storeApi } from '../services/store';
import { couponsApi } from '../services/coupons';
import { actionApi } from '../services/action';
import { refundApi } from '../services/refund';
import { inventoryApi } from '../services/inventory';
import { BoxApi } from '../services/box';
import { cachedApi } from '../services/cached';
import authReducer from "../reducers/authReducer";

export const store = configureStore({
  reducer: {
    auth: authReducer,
    [productsApi.reducerPath]: productsApi.reducer,
    [ordersApi.reducerPath]: ordersApi.reducer,
    [orderItemsApi.reducerPath]: orderItemsApi.reducer,
    [UsersApi.reducerPath]: UsersApi.reducer,
    [MembershipApi.reducerPath]: MembershipApi.reducer,
    [loginApi.reducerPath]: loginApi.reducer,
    [mediaApi.reducerPath]: mediaApi.reducer,
    [countriesApi.reducerPath]: countriesApi.reducer,
    [routeOneApi.reducerPath]: routeOneApi.reducer,
    [teamApi.reducerPath]: teamApi.reducer,
    [googleMApi.reducerPath]: googleMApi.reducer,
    [storeApi.reducerPath]: storeApi.reducer,
    [couponsApi.reducerPath]: couponsApi.reducer,
    [actionApi.reducerPath]: actionApi.reducer,
    [refundApi.reducerPath]: refundApi.reducer,
    [inventoryApi.reducerPath]: inventoryApi.reducer,
    [BoxApi.reducerPath]: BoxApi.reducer,
    [cachedApi.reducerPath]: cachedApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(productsApi.middleware).concat(ordersApi.middleware).concat(orderItemsApi.middleware).concat(UsersApi.middleware).concat(MembershipApi.middleware).concat(loginApi.middleware).concat(mediaApi.middleware).concat(teamApi.middleware).concat(countriesApi.middleware).concat(routeOneApi.middleware).concat(storeApi.middleware).concat(googleMApi.middleware).concat(couponsApi.middleware).concat(actionApi.middleware).concat(refundApi.middleware).concat(inventoryApi.middleware).concat(cachedApi.middleware).concat(BoxApi.middleware)
})
setupListeners(store.dispatch)
