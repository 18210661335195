import React, { useState, useEffect, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, useLocation, useParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import './index.scss';
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import { useSelector } from "react-redux";
import { useUpdateMutation } from '../../services/cached';
import LinearProgress from '@mui/material/LinearProgress';
import PageError from '../PageError';


function ManageKeysC() {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);

  const [updateinventory, updateresult] = useUpdateMutation()


  return (
    <>

      <div className="row" style={{ margin: 0, padding: 0, position: 'relative' }}>
        <div style={{ padding: 0, width: '100%' }}>
          <div className="tabelOverflow" style={{ position: 'relative' }}>
            {updateresult?.isLoading &&
              <LinearProgress style={{ height: '2px' }} />
            }
          </div>
        </div>
      </div>



      <div className="row" style={{ margin: 0, padding: 0, marginTop: '25px' }}>
        <div className="col-md-12">
          <div style={{ display: 'flex', alignItems: 'center', fontSize: '1rem', fontWeight: 500, textTransform: 'uppercase', marginBottom: '15px', color: '#856404' }}>
            Manage cached keys on the website
          </div>
        </div>
      </div>
      <br />



      {updateresult?.error?.originalStatus === 403 &&
        <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
          <div className='alert alert-danger' style={{ background: '#fff0', border: 'none' }}>{updateresult?.error?.data}</div>
        </div>
      }


      <div className="row" style={{ margin: 0, padding: 0 }}>
        <div className="newFlexPagi" style={{ borderTop: '1px solid #e7e9f1' }}>
          <div className="col-md-12">
            <div className="storeCardB">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap', flexWrap: 'wrap' }}>
                <div>Popular Products</div>
                <div className="Order-Pagination">
                  <Button
                    variant='soft'
                    size='sm'
                    style={{ fontWeight: 400 }}
                    loading={updateresult?.isLoading && (updateresult?.originalArgs?.name === 'popular')}
                    loadingIndicator="Loading…"
                    onClick={(() => updateinventory({
                      key: 'popular',
                      id: 'popular',
                      userId: userId,
                      userToken: userToken,
                      admin: admin,
                      name: 'popular'
                    }))}
                  >
                    Reset/Generate New Key
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="newFlexPagi" style={{ borderTop: '1px solid #e7e9f1' }}>
          <div className="col-md-12">
            <div className="storeCardB">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap', flexWrap: 'wrap' }}>
                <div>Trending Products</div>
                <div className="Order-Pagination">
                  <Button
                    variant='soft'
                    size='sm'
                    style={{ fontWeight: 400 }}
                    loading={updateresult?.isLoading && (updateresult?.originalArgs?.name === 'trending')}
                    loadingIndicator="Loading…"
                    onClick={(() => updateinventory({
                      key: 'trending',
                      id: 'trending',
                      userId: userId,
                      userToken: userToken,
                      admin: admin,
                      name: 'trending'
                    }))}
                  >
                    Reset/Generate New Key
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="newFlexPagi" style={{ borderTop: '1px solid #e7e9f1' }}>
          <div className="col-md-12">
            <div className="storeCardB">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap', flexWrap: 'wrap' }}>
                <div>Product Page related products</div>
                <div className="Order-Pagination">
                  <Button
                    variant='soft'
                    size='sm'
                    style={{ fontWeight: 400 }}
                    loading={(updateresult?.isLoading) && (updateresult?.originalArgs?.name === 'related')}
                    loadingIndicator="Loading…"
                    onClick={(() => updateinventory({
                      key: 'related',
                      id: 'related',
                      userId: userId,
                      userToken: userToken,
                      admin: admin,
                      name: 'related'
                    }))}
                  >
                    Reset/Generate New Key
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="newFlexPagi" style={{ borderTop: '1px solid #e7e9f1' }}>
          <div className="col-md-12">
            <div className="storeCardB">
              <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', overflow: 'auto', width: '100%', whiteSpace: 'nowrap', flexWrap: 'wrap' }}>
                <div>Inventory Deals</div>
                <div className="Order-Pagination">
                  <Button
                    variant='soft'
                    size='sm'
                    style={{ fontWeight: 400 }}
                    loading={(updateresult?.isLoading) && (updateresult?.originalArgs?.name === 'deals')}
                    loadingIndicator="Loading…"
                    onClick={(() => updateinventory({
                      key: 'inventory_deals',
                      id: 'deals',
                      userId: userId,
                      userToken: userToken,
                      admin: admin,
                      name: 'deals'
                    }))}
                  >
                    Reset/Generate New Key
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </div>





      </div>


    </>
  );
}

export default ManageKeysC;
