import React from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Button from '@mui/joy/Button';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/joy/IconButton';

function InventoryList({ inventoryData, deleteinventory, deleteresult, addQuantity, removeQuantity, updateresult }) {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);

  function getvariants(variant) {
    if (!variant || typeof variant !== 'object' || Array.isArray(variant)) {
      return '';
    } else {
      return <div>
        {Object.entries(variant)?.map(([key, value], index) => (
          <div key={index} style={{ fontSize: '12px' }}>
            <span style={{ fontWeight: 600 }}>{key}:&nbsp;</span> {value}
          </div>
        ))}
      </div>
    }
  }

  const ComponentToPrintWrapper = ({ item }) => {
    return (
      <tr style={{ position: 'relative' }}>
        <td>
          {item.id}
        </td>
        <td>
          <span className='badge badge-info'> {item.box?.box_number}</span>
        </td>
        <td style={{ textAlign: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>

            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Link className="orders-page-item-link" to={`/product/${item.id}`}
                style={{ margin: '0 5px', border: `1px solid #eee`, background: '#fff', padding: '3px', borderRadius: '4px', position: 'relative', zIndex: 111 }}>
                <img src={`${item.image}`} style={{ width: '35px', height: '35px', objectFit: 'contain' }} />
              </Link>
            </div>
          </div>
        </td>
        <td>
          <Link to={`/product/${item.id}`} style={{ color: '#5a5858' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${item.country?.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${item.country?.toLowerCase()}.png 2x`}
                alt=""
              />
              <span className='Ip-title' style={{ margin: '0px 5px' }}> {item.title}</span>
            </div>
          </Link>
        </td>
        <td>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <div>
              {item.currency}&nbsp;{Number(item.price)}
            </div>
          </div>
        </td>
        <td>
          <div className="dFlex center nowrap jcr" style={{ justifyContent: 'space-between', border: '1px solid #eee', borderRadius: '100px' }}>
          <IconButton
              disabled={updateresult?.isLoading || item.qty === 1}
              onClick={(() => removeQuantity(item.id, item.qty, item.id))}
              size='sm'
              variant='outlined'
              style={{ borderRadius: '100px' }}>
              <RemoveIcon style={{ fontSize: '15px' }} />
            </IconButton>
            <span
              style={{ margin: '0px 10px' }}
              className="alert boot-alert-style">
              {item.qty}
            </span>
            <IconButton
              disabled={updateresult?.isLoading || (item.qty === 100 || item.qty > 100)}
              onClick={(() => addQuantity(item.id, item.qty, item.id))}
              size='sm'
              variant='outlined'
              style={{ borderRadius: '100px' }}>
              <AddIcon style={{ fontSize: '15px' }} />
            </IconButton>

          </div>
        </td>
        <td>
          <div>{item.condition}</div>
        </td>
        <td>
          {getvariants(item.current_variant)}
        </td>
        <td className="sticky-column">
          <div className="dFlex center nowrap jcr" style={{ justifyContent: 'right' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Button
                color='danger'
                variant='outlined'
                disabled={deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.id}
                onClick={(() => deleteinventory({
                  id: item.id,
                  userId: userId,
                  userToken: userToken,
                  admin: admin,
                }))}
                style={{
                  textTransform: 'capitalize',
                  fontSize: '13px',
                  fontWeight: 400,
                  minHeight: '15px',
                  padding: '0px 6px'
                }}>
                <span>
                  {deleteresult?.isLoading && deleteresult?.originalArgs?.id === item.id ?
                    <CircularProgress style={{ width: '10px', height: '10px', color: '#000' }} />
                    :
                    <>
                      Delete
                    </>
                  }
                </span>
              </Button>
            </div>
          </div>
        </td>
      </tr>
    );
  };


  return (
    <>
      {inventoryData?.inventory?.map(function (item, index) {
        return <ComponentToPrintWrapper key={index} item={item} />;
      })}
    </>
  );
}

export default InventoryList;
