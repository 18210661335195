import React, { useState, useEffect, useRef, Fragment } from 'react';
import { BrowserRouter as Router, Switch, Route, Link, useHistory, Redirect, useLocation, useParams } from "react-router-dom";
import { Navbar } from "react-bootstrap";
import "./index.scss";
import "react-minimal-side-navigation/lib/ReactMinimalSideNavigation.css";
import { Menu as Menus } from 'antd';
import { useDispatch, useSelector } from "react-redux";
import { removeToCart, addMenuKey } from "../../reducers/authReducer";
import SearchIcon from "@material-ui/icons/Search";
import IconButton from '@mui/joy/IconButton';
import Button from '@mui/joy/Button';
import { useForm } from "react-hook-form";
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import { useMediaQuery } from 'react-responsive'
import MenuOpenIcon from '@mui/icons-material/MenuOpen';
import MenuIcon from '@mui/icons-material/Menu';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';
import StorefrontOutlinedIcon from '@mui/icons-material/StorefrontOutlined';
import DiscountOutlinedIcon from '@mui/icons-material/DiscountOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import ArchiveOutlinedIcon from '@mui/icons-material/ArchiveOutlined';
import LocalMallOutlinedIcon from '@mui/icons-material/LocalMallOutlined';
import FormatListBulletedOutlinedIcon from '@mui/icons-material/FormatListBulletedOutlined';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import ManageAccountsOutlinedIcon from '@mui/icons-material/ManageAccountsOutlined';
import PeopleAltOutlinedIcon from '@mui/icons-material/PeopleAltOutlined';
import PortraitOutlinedIcon from '@mui/icons-material/PortraitOutlined';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ListAltIcon from '@mui/icons-material/ListAlt';
import OutlinedFlagIcon from '@mui/icons-material/OutlinedFlag';
import HistoryIcon from '@mui/icons-material/History';
import ScheduleIcon from '@mui/icons-material/Schedule';
import OutputIcon from '@mui/icons-material/Output';
function HeaderMenu(props) {
  const dispatch = useDispatch();
  const { userId, userToken, userEmail, userName, isStatus, userRole, languageCode, sideMenuKey, domain, adminAccess } = useSelector((state) => state.auth);
  function handleClick(e) {
    console.log('', e);
  }
  const { register, handleSubmit, reset, formState: { errors } } = useForm();
  const history = useHistory();
  const location = useLocation();
  const { number } = useParams();
  const isMobcreen = useMediaQuery({ query: '(min-width: 0px) and (max-width: 992px)' })
  function userLogout() {
    dispatch(removeToCart());
    dispatch(addMenuKey({ sideMenuKey: '', }));
    history.push(`/`);
  }

  const orderLocationSearch = new URLSearchParams(location.search).get('order') || null
  const productLocationSearch = new URLSearchParams(location.search).get('product') || null

  const [collapsed, setStatesss] = useState(false)
  function toggleCollapsed() {
    setStatesss(!collapsed)
  };

  const thisPath = window.location.pathname?.split('/')
  thisPath?.pop()
  const thisPathSplit = thisPath?.join('/')
  const thisPathName = thisPathSplit?.split('/')
  thisPathName?.pop()

  function displayMenu() {
    return (
      <div className="dFlex mobile-hide" style={{ margin: '0 10px' }}>
        <div className="dFlex">
          <span style={{ display: 'flex', alignItems: 'center', fontWeight: 400, color: '#5e6f82', fontSize: '1rem', textTransform: 'capitalize', whiteSpace: 'nowrap' }}>
            {
              thisPath?.includes('box') ?
                thisPathName?.join('/')?.replace(/-/g, ' ')?.slice(1).replace(/\//g, ' > ') : window.location.pathname?.replace(/-/g, ' ')?.startsWith('/') ?
                  window.location.pathname?.replace(/-/g, ' ')?.slice(1).replace(/\//g, ' > ')
                  : window.location.pathname?.replace(/-/g, ' ')?.replace(/\//g, ' > ')
            }
            {number &&
              <span style={{ margin: '0px 5px' }}>{`>`} {number}</span>
            }
          </span>

        </div>
      </div>
    )
  }



  function setNevActive(path) {
    if (window.location.pathname?.includes(path)) {
      return true
    } else {
      return false
    }
  }

  function getThisPath(path1, path2, path3) {
    if (window.location.pathname?.includes(path1)) {
      return true
    } else if (window.location.pathname?.includes(path2)) {
      return true
    } else if (window.location.pathname?.includes(path3)) {
      return true
    } else {
      return false
    }
  }

  function getOrderActive(path1, path2) {
    if (window.location.pathname?.includes(path1)) {
      return true
    } else if (window.location.pathname?.includes(path2)) {
      return true
    } else {
      return false
    }
  }
  function getUsersActive(path1, path2) {
    if (window.location.pathname?.includes(path1)) {
      return true
    } else if (window.location.pathname?.includes(path2)) {
      return true
    } else {
      return false
    }
  }

  function setNevActived(path) {
    if (window.location.pathname === '/') {
      return true
    } else {
      return false
    }
  }

  function changeUrl(path, subkey) {
    history.push(path)
    // dispatch(addMenuKey({ sideMenuKey: subkey, }));
    if (isMobcreen) {
      props.toggleCollapsed(true)
    }
  }

  function isAcceess(name) {
    const accessArray = adminAccess ? adminAccess?.access : [];
    if (accessArray.includes('full_access')) {
      return true;
    } else {
      return accessArray.includes(name);
    }
  }

  const [openKeys, setOpenKeys] = useState([]);

  const thisKeyList = sideMenuKey?.split(',')


  const [getSearchType, setSearchType] = useState(orderLocationSearch ? 'order_search' : 'product_search');

  function onSubmit(data) {
    if (getSearchType === 'product_search') {
      history.push(`/product/${data.product}?product=${data.product}`);
    }
    if (getSearchType === 'order_search') {
      history.push(`/orders/search/${data.product}?order=${data.product}`);
    }
    reset()
  }

  const items = [
    {
      key: '1',
      title: '',
      label:
        <div
          style={{ cursor: 'auto', position: 'fixed', top: '0px', zIndex: 111, background: '#fcfcfc', width: !props.collapsed ? '234px' : '64px', height: '48px' }}
          disabled
          variant={'plain'}
          className={`thisMenu lg-m-menu cursor-auto`}
        >
          <svg width="18" height="18" fill="#ee6a12" className="bi bi-person-workspace" viewBox="0 0 16 16">
            <path d="M4 16s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1zm4-5.95a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5" />
            <path d="M2 1a2 2 0 0 0-2 2v9.5A1.5 1.5 0 0 0 1.5 14h.653a5.4 5.4 0 0 1 1.066-2H1V3a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v9h-2.219c.554.654.89 1.373 1.066 2h.653a1.5 1.5 0 0 0 1.5-1.5V3a2 2 0 0 0-2-2z" />
          </svg>
          {!props.collapsed &&
            <span style={{ margin: '0px 10px', fontWeight: 400, color: '#000', fontSize: '1.1rem', textTransform: 'capitalize' }} className='admin-email'>
              {domain}
            </span>
          }
        </div>
    },
    {
      key: '2',
      title: 'Dashboard',
      label:
        <Button
          variant={setNevActived('/') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActived('/ ') && `thiActive`}`}
          onClick={(() => changeUrl('/'))}
        >
          <GridViewOutlinedIcon style={{ fontSize: '1.4rem' }} />
          {!props.collapsed &&
            <span style={{ margin: '0px 10px' }} className='admin-email'>
              Dashboard
            </span>
          }
        </Button>
    },

    isAcceess('google_merchants') && {
      key: '3',
      title: 'Google Merchants',
      label:
        <Button
          variant={setNevActive('google-merchant') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActive('google-merchant') && `thiActive`}`}
          onClick={(() => changeUrl('/google-merchants'))}
        >
          <svg width="17" height="17" fill={`${'#5e6f82'}`} className="bi bi-google" viewBox="0 0 16 16">
            <path d="M15.545 6.558a9.4 9.4 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.7 7.7 0 0 1 5.352 2.082l-2.284 2.284A4.35 4.35 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.8 4.8 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.7 3.7 0 0 0 1.599-2.431H8v-3.08z" />
          </svg>
          {!props.collapsed &&
            <span style={{ margin: '0px 10px' }} className='admin-email'>
              Google Merchant
            </span>
          }
        </Button>
    },
    isAcceess('coupons') && {
      key: '4',
      title: 'Counpons',
      label:
        <Button
          variant={setNevActive('coupon') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActive('coupon') && `thiActive`}`}
          onClick={(() => changeUrl('/coupons'))}
        >
          <DiscountOutlinedIcon style={{ fontSize: '1.4rem' }} />
          {!props.collapsed &&
            <span style={{ margin: '0px 10px' }} className='admin-email'>
              Coupons</span>}
        </Button>
    },

    {
      type: 'divider',
    },

    isAcceess('products') && {
      key: 'inventorysub',
      title: 'Inventory',
      expandIcon: !props.collapsed ? thisKeyList?.includes('inventorysub') ?
        <ArrowDropDownIcon className='active-color' style={{ fontSize: '1.4rem', color: '#6f7072' }} /> :
        <ArrowRightIcon className='active-color' style={{ fontSize: '1.4rem', color: '#6f7072' }} /> : '',
      label:
        <div className={`sub-menu ${getThisPath('box', 'inventory') && `sub-menu-active`}`}>
          {props.collapsed && <InventoryOutlinedIcon style={{ fontSize: '1.4rem', color: '#5e6f82' }} />}
          {!props.collapsed && <span style={{ margin: '0px 10px', color: '#5e6f82' }} className='menu-active-color admin-email'>Inventory</span>}
        </div>,
      children: [
        isAcceess('stores') && {
          key: '5',
          label:
            <div
              className={`thisMenu ${setNevActive('box') && `sub-menu-item-active`}`}
              onClick={(() => changeUrl('/boxes', 'inventorysub'))}>

              <ArchiveOutlinedIcon style={{ fontSize: '1.4rem' }} />
              <span className="sub-menu-item-title">Boxes</span>
            </div>
        },
        {
          key: '6',
          label:
            <div
              className={`thisMenu ${setNevActive('inventory') && `sub-menu-item-active`}`}
              onClick={(() => changeUrl('/inventory', 'inventorysub'))}>

              <ListAltIcon style={{ fontSize: '1.4rem' }} />
              <span className="sub-menu-item-title">Inventory List</span>
            </div>
        },
      ],
    },
    isAcceess('products') &&
    {
      type: 'divider',
    },

    (isAcceess('products') || isAcceess('stores')) && {
      key: 'productssub',
      title: 'Products',
      expandIcon: !props.collapsed ? thisKeyList?.includes('productssub') ?
        <ArrowDropDownIcon className='active-color' style={{ fontSize: '1.4rem', color: '#6f7072' }} /> :
        <ArrowRightIcon className='active-color' style={{ fontSize: '1.4rem', color: '#6f7072' }} /> : '',
      label:
        <div className={`sub-menu ${getThisPath('product', 'store') && `sub-menu-active`}`}>
          {props.collapsed && <FormatListBulletedOutlinedIcon style={{ fontSize: '1.4rem', color: '#5e6f82' }} />}
          {!props.collapsed && <span style={{ margin: '0px 10px', color: '#5e6f82' }} className='menu-active-color admin-email'>Products</span>}
        </div>,
      children: [
        isAcceess('stores') && {
          key: '7',
          label:
            <div
              className={`thisMenu ${setNevActive('store') && `sub-menu-item-active`}`}
              onClick={(() => changeUrl('/stores', 'productssub'))}>

              <StorefrontOutlinedIcon style={{ fontSize: '1.4rem' }} />
              <span className="sub-menu-item-title">Stores</span>
            </div>
        },
        {
          key: '8',
          label:
            <div
              className={`thisMenu ${setNevActive('product') && `sub-menu-item-active`}`}
              onClick={(() => changeUrl('/products', 'productssub'))}>

              <ListAltIcon style={{ fontSize: '1.4rem' }} />
              <span className="sub-menu-item-title">Product List</span>
            </div>
        },
      ],
    },
    (isAcceess('products') || isAcceess('stores')) &&
    {
      type: 'divider',
    },
    isAcceess('orders') && {
      key: 'orderssub',
      title: 'Orders',
      expandIcon: !props.collapsed ? thisKeyList?.includes('orderssub') ?
        <ArrowDropDownIcon className='active-color' style={{ fontSize: '1.4rem', color: '#6f7072' }} /> :
        <ArrowRightIcon className='active-color' style={{ fontSize: '1.4rem', color: '#6f7072' }} /> : '',
      label:
        <div className={`sub-menu ${getOrderActive('order', 'items') && `sub-menu-active`}`}>
          {props.collapsed && <LocalMallOutlinedIcon style={{ fontSize: '1.4rem', color: '#5e6f82' }} />}
          {!props.collapsed && <span style={{ margin: '0px 10px', color: '#5e6f82' }} className='menu-active-color admin-email'>Orders</span>}
        </div>,
      children: [
        {
          key: '9',
          label: <div
            className={`thisMenu ${setNevActive('order') && `sub-menu-item-active`}`}
            onClick={(() => changeUrl('/orders', 'orderssub'))}>
            <InventoryOutlinedIcon style={{ fontSize: '1.4rem' }} />
            <span className="sub-menu-item-title">Order List</span>
          </div>
        },
        {
          key: '10',
          label:
            <div
              className={`thisMenu ${setNevActive('items') && `sub-menu-item-active`}`}
              onClick={(() => changeUrl('/items', 'orderssub'))}>
              <ListAltIcon style={{ fontSize: '1.4rem' }} />
              <span className="sub-menu-item-title">Order Items</span>
            </div>
        },
        isAcceess('orders_csv') && {
          key: '010',
          label:
            <div
              className={`thisMenu ${setNevActive('generate') && `sub-menu-item-active`}`}
              onClick={(() => changeUrl('/generate-csv', 'orderssub'))}>
              <ScheduleIcon style={{ fontSize: '1.4rem' }} />
              <span className="sub-menu-item-title">Generate CSV</span>
            </div>
        },
        isAcceess('createorder') && {
          key: '011',
          label: <div
            className={`thisMenu ${setNevActive('/create') && `sub-menu-item-active`}`}
            onClick={(() => changeUrl('/create', 'orderssub'))}>
            <PostAddIcon style={{ fontSize: '1.4rem' }} />
            <span className="sub-menu-item-title">Create Order</span>
          </div>
        },
      ],
    },
    isAcceess('orders') &&
    {
      type: 'divider',
    },
    isAcceess('orders') && {
      key: '11',
      title: 'Shipments',
      label:
        <Button
          variant={setNevActive('shipments') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActive('shipment') && `thiActive`}`}
          onClick={(() => changeUrl('/shipments'))}
        >
          <OutputIcon style={{ fontSize: '1.4rem' }} />
          {!props.collapsed && <span style={{ margin: '0px 10px' }} className='admin-email'>Shipments</span>}
        </Button>
    },
    isAcceess('refunds') && {
      key: '12',
      title: 'Refunds',
      label:
        <Button
          variant={setNevActive('refunds') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActive('refunds') && `thiActive`}`}
          onClick={(() => changeUrl('/refunds'))}
        >
          <PaymentsOutlinedIcon style={{ fontSize: '1.4rem' }} />
          {!props.collapsed && <span style={{ margin: '0px 10px' }} className='admin-email'>Refunds</span>}
        </Button>
    },
    isAcceess('countries') && {
      key: '13',
      title: 'Countries',
      label:
        <Button
          variant={setNevActive('countries') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActive('countries') && `thiActive`}`}
          onClick={(() => changeUrl('/countries/routeTwo'))}
        >
          <OutlinedFlagIcon style={{ fontSize: '1.4rem' }} />
          {!props.collapsed && <span style={{ margin: '0px 10px' }} className='admin-email'>Countries</span>}
        </Button>
    },
    (isAcceess('orders') || isAcceess('countries') || isAcceess('refunds')) &&
    {
      type: 'divider',
    },
    (isAcceess('team') || isAcceess('users')) && {
      key: 'userssub',
      title: 'Users',
      expandIcon: !props.collapsed ? thisKeyList?.includes('userssub') ?
        <ArrowDropDownIcon className='active-color' style={{ fontSize: '1.4rem', color: '#6f7072' }} /> :
        <ArrowRightIcon className='active-color' style={{ fontSize: '1.4rem', color: '#6f7072' }} /> : '',
      label:
        <div className={`sub-menu ${getUsersActive('user', 'team') && `sub-menu-active`}`}>
          {props.collapsed && <ManageAccountsOutlinedIcon style={{ fontSize: '1.4rem', color: '#5e6f82' }} />}
          {!props.collapsed && <span style={{ margin: '0px 10px', color: '#5e6f82' }} className='menu-active-color admin-email'>Accounts</span>}
        </div>,
      children: [
        isAcceess('users') && {
          key: '14',
          label: <div
            className={`thisMenu ${setNevActive('user') && `sub-menu-item-active`}`}
            onClick={(() => changeUrl('/users', 'userssub'))}>
            <PeopleAltOutlinedIcon style={{ fontSize: '1.4rem' }} />
            <span className="sub-menu-item-title">Users</span>
          </div>
        },
        isAcceess('team') && {
          key: '15',
          label: <div
            className={`thisMenu ${setNevActive('team') && `sub-menu-item-active`}`}
            onClick={(() => changeUrl('/team', 'userssub'))}>
            <PortraitOutlinedIcon style={{ fontSize: '1.4rem' }} />
            <span className="sub-menu-item-title">Admin</span>
          </div>
        },
      ],
    },
    (isAcceess('team') || isAcceess('users')) &&
    {
      type: 'divider',
    },
    isAcceess('media') && {
      key: '16',
      title: 'Media',
      label:
        <Button
          variant={setNevActive('media') ? `soft` : 'plain'}
          className={`thisMenu ${setNevActive('media') && `thiActive`}`}
          onClick={(() => changeUrl('/media'))}
        >
          <PermMediaOutlinedIcon style={{ fontSize: '1.4rem' }} />
          {!props.collapsed && <span style={{ margin: '0px 10px' }} className='admin-email'>Media</span>}
        </Button>
    },
    isAcceess('media') &&
    {
      type: 'divider',
    },

    {
      key: 'settingssub',
      title: 'Settings',
      expandIcon: !props.collapsed ? thisKeyList?.includes('settingssub') ?
        <ArrowDropDownIcon className='active-color' style={{ fontSize: '1.4rem', color: '#6f7072' }} /> :
        <ArrowRightIcon className='active-color' style={{ fontSize: '1.4rem', color: '#6f7072' }} /> : '',
      label:
        <div className={`sub-menu ${getUsersActive('manage-keys') && `sub-menu-active`}`}>
          {props.collapsed && <SettingsOutlinedIcon style={{ fontSize: '1.4rem', color: '#5e6f82' }} />}
          {!props.collapsed && <span style={{ margin: '0px 10px', color: '#5e6f82' }} className='menu-active-color admin-email'>Settings</span>}
        </div>,
      children: [
        isAcceess('cached') && {
          key: '17',
          label: <div
            className={`thisMenu ${setNevActive('manage-keys') && `sub-menu-item-active`}`}
            onClick={(() => changeUrl('/manage-keys', 'settingssub'))}>
            <HistoryIcon style={{ fontSize: '1.4rem' }} />
            <span className="sub-menu-item-title">Cached keys</span>
          </div>
        },
      ],
    },

    {
      type: 'divider',
    },

  ]

  const onOpenChange = (keys) => {
    dispatch(addMenuKey({ sideMenuKey: keys?.toString() }));
    setOpenKeys(keys);
  };

  return (
    <div className="ML-Header">

      <Menus
        openKeys={thisKeyList}
        onOpenChange={onOpenChange}
        // onClick={handleClick}
        mode="inline"
        inlineCollapsed={props.collapsed}
        className="sideMenuN side-menubar"
        // defaultSelectedKeys={[sideMenuKey]}
        // defaultOpenKeys={['settingssub']}
        items={items}
      >

      </Menus>
      <Navbar className={`topNavBar ${props.collapsed && 'menuCollapsed'}`} bg="white" expand="lg">
        <div className="addAllIconCard">
          <div>
            <span style={{ display: 'flex', alignItems: 'center' }} className={`syncIcon`} onClick={() => props.toggleCollapsed({ toggleCollapsed })}>
              {/* {React.createElement(MenuIcon)} */}
              <IconButton>
                {props.collapsed ?
                  <MenuOpenIcon style={{ color: '#5e6f82', fontSize: '1.6rem' }} /> :
                  <MenuIcon style={{ color: '#5e6f82', fontSize: '1.5rem' }} />
                }
              </IconButton>
            </span>
          </div>
          {displayMenu()}
        </div>
        <div className="headerRightCard">
          <form onSubmit={handleSubmit(onSubmit)} className="table_search_card">
            <div className='table_search1' style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton
                style={{ padding: '0px 5px 0px 5px', minHeight: '25px', maxHeight: '25px' }}
                onClick={(() => setSearchType('product_search'))}
                variant={getSearchType === 'product_search' ? 'outlined' : 'soft'}
                size='sm'>
                <small>P<span className='mobile-hide'>roducts</span></small>
              </IconButton>
              <IconButton
                style={{ padding: '0px 5px 0px 5px', minHeight: '25px', maxHeight: '25px', margin: '0px 5px' }}
                onClick={(() => setSearchType('order_search'))}
                variant={getSearchType === 'order_search' ? 'outlined' : 'soft'}
                size='sm'>
                <small>O<span className='mobile-hide'>rders</span></small>
              </IconButton>
              <input
                type="text"
                className="form-control table_search"
                placeholder={`Search for ${getSearchType?.split('_')[0]}...`}
                id="product"
                aria-invalid={errors.product ? "true" : "false"}
                {...register("product", { required: true })}
              />
              <IconButton style={{ borderRadius: '100px' }} aria-haspopup="true" type="submit" className="table_search_icon">
                <SearchIcon style={{ fontSize: 'inherit' }} />
              </IconButton>
            </div>
          </form>

          <Dropdown>
            <MenuButton
              variant='soft'
              style={{
                display: 'flex',
                alignItems: 'center',
                width: '40px',
                height: '40px',
                lineHeight: '40px',
                borderRadius: '100px',
                fontSize: '18px',
                justifyContent: 'center',
                padding: '5px'
              }}
            >
              {userEmail ? userEmail?.charAt(0).toUpperCase() : ''}
            </MenuButton>
            <Menu>
              <div style={{ padding: '10px 15px 10px 15px' }}>
                {userEmail}
              </div>
              <MenuItem
                key="1"
                component={Link}
                to={`/manage-keys`}
              >
                <div style={{ fontSize: '1rem' }}>Manage keys</div>
              </MenuItem>
              <MenuItem onClick={(() => userLogout())} key="2">
                <div style={{ fontSize: '1rem' }}>Logout</div>
              </MenuItem>
            </Menu>
          </Dropdown>

        </div>
      </Navbar >
    </div >
  );
}

export default HeaderMenu;
