import React from 'react';
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from 'moment'
import IconButton from '@mui/material/IconButton';
import Button from '@mui/joy/Button';
import AtmIcon from '@mui/icons-material/Atm';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AppleIcon from '@mui/icons-material/Apple';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Dropdown from '@mui/joy/Dropdown';
import Menu from '@mui/joy/Menu';
import MenuButton from '@mui/joy/MenuButton';
import MenuItem from '@mui/joy/MenuItem';
import Barcode from 'react-barcode';
import ReactToPrint from "react-to-print";
import CircularProgress from '@mui/material/CircularProgress';
import WarehouseOutlinedIcon from '@mui/icons-material/WarehouseOutlined';

const userEmailIds = ['ali.akbar@ebtida.com', 'fahd@ebtida.com', 'hesham@ebtida.com', 'salman@ebtida.com', 'faiz@ebtida.com', 'hamza@ebtida.com', 'jauhar@ebtida.com', 'anas@ebtida.com']


function OrderItemsList({ ordersData, updatestatesresults, updatestates }) {
  const { userId, userToken, userEmail, userName, status, admin } = useSelector((state) => state.auth);

  function shippingStatusC(data) {

    function getthisplit(thisData) {
      const values = thisData?.split('_');
      if (values?.includes('arrived_')) {
        return 'shipping'
      } else if (values?.includes('departed')) {
        return 'shipping'
      } else if (values?.includes('dispatched')) {
        return 'procured'
      } else {
        return thisData
      }
    }
    let color
    if (getthisplit(data) === 'delivered') {
      color = 'badge badge-success'
    } else if (getthisplit(data) === 'cancel') {
      color = 'badge badge-danger'
    } else if (getthisplit(data) === 'processing') {
      color = 'badge badge-info'
    } else {
      color = 'badge badge-warning'
    }
    return { name: getthisplit(data)?.replace(/_/g, ' '), color: color }
  }


  function borderColor(data) {
    let color
    if (data === 'delivered') {
      color = '#00800108'
    } else if (data === 'cancelled') {
      color = '#cd201f05'
    } else {
      color = '#ffa5000a'
    }
    return color
  }


  function getPaymentTypeIcon(type) {
    if (type === 'card') {
      return <CreditCardIcon style={{ fontSize: '15px' }} />
    } else if (type === 'apple_pay') {
      return <AppleIcon style={{ fontSize: '15px' }} />
    } else if (type === 'bank_transfer') {
      return <AtmIcon style={{ fontSize: '15px' }} />
    } else if (type === 'tap') {
      return <img src='Logo_7de102aab6.svg' style={{width: '20px'}} />
    } else if (type === 'tap_tabby') {
      return <img src='tabby-image.png' style={{width: '20px'}} />
    } else {
      return ''
    }
  }


  function getLocalFlagState(flag) {
    const hostName = flag?.split(",");
    return hostName?.includes('local') ? true : false;
  }

  function getImportantFlagState(flag) {
    const hostName = flag?.split(",");
    return hostName?.includes('important') ? true : false;
  }

  function flagsStatus(data) {
    let color
    if (getLocalFlagState(data)) {
      color = 'badge badge-dark'
    } else {
      color = 'badge badge-info'
    }
    return { name: 'Local', color: color }

  }

  function getUserEmailState(data) {
    const hostName = data?.users?.split(",");
    return hostName?.includes(data?.email) ? true : false;
  }

  function isDelayed(create, days) {
    let today = moment().format()
    const exist = moment(create).add(Number(days), 'days').format()
    if (today > exist) {
      return true
    } else {
      return false
    }
  }

  function isDelayedStatus(data, state) {
    data = data.trim();
    const values = data.split(',');
    const thisstatus = values[values?.length - 1].trim();
    if (thisstatus === 'delivered') {
      return false
    } else if (thisstatus === 'cancel') {
      return false
    } else if (thisstatus === 'cancelled') {
      return false
    } else if (state === 'cancelled') {
      return false
    } else {
      return true
    }
  }


  const config = {
    width: 1,
    height: 45,
    format: "CODE128",
    displayValue: true,
    fontOptions: "",
    font: "monospace",
    textAlign: "center",
    textPosition: "bottom",
    textMargin: 2,
    fontSize: 14,
    background: "#fff0",
    lineColor: "#000000",
    margin: 0,
    marginTop: undefined,
    marginBottom: undefined,
    marginLeft: undefined,
    marginRight: undefined,
    id: undefined,
    className: undefined,
    displayValue: false
  }

  const ComponentToPrint = React.forwardRef((props, ref) => {
    const { value, orderId, title } = props;
    return (
      <div style={{ display: 'none' }}>
        <div className='react-barcode' style={{ padding: '0px', textAlign: 'center' }} ref={ref}>
          <div>{orderId}-{value}</div>
          <Barcode value={value} {...config} />
          <div style={{ padding: '5px 0px 0px 0px' }}>{title?.substring(0, 34)?.replace(/-/g, ' ')}...</div>
        </div>
      </div>
    );
  });

  function getMessageCount(messages, message) {
    if (messages?.length > 0) {
      return true
    } else if (message?.length > 0) {
      return true
    } else {
      return false
    }
  }


  const ComponentToPrintWrapper = ({ item }) => {
    const componentRef = React.useRef();
    return (
      <tr style={{ position: 'relative' }}>
        <td>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Dropdown>
              <MenuButton
                variant='outlined'
                size='sm'
                style={{ borderRadius: '4px', minHeight: '18px', maxHeight: '18px', padding: '0px', marginRight: '5px' }}
                className="Header-button-connect">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                  <ArrowDropDownIcon style={{ fontSize: '1.2rem' }} />
                </div>
              </MenuButton>
              <Menu>
                {userEmailIds?.map((email, i) =>
                  <MenuItem key={i} className='orders-sub-side-menu' onClick={(() =>
                    updatestates({
                      id: item.order?.id,
                      domain: item.order?.domain,
                      email: email,
                      users: true,
                      userId: userId,
                      userToken: userToken,
                      admin: admin,
                    }))}>
                    <div style={{ display: 'flex', alignItems: 'center', fontSize: '14px' }}>
                      <input style={{ margin: '0px 5px' }} type='checkbox' checked={getUserEmailState({ users: item.order?.users, email: email }) ? true : false} />
                      <div style={{ textAlign: 'left' }}>{email}</div>
                    </div>
                  </MenuItem>
                )}
              </Menu>
            </Dropdown>
            {/* <ReactToPrint
              trigger={() =>
                <IconButton title='Print barcode'>
                  <svg xmlns="http://www.w3.org/2000/svg" width="13" height="13" fill="currentColor" className="bi bi-qr-code" viewBox="0 0 16 16">
                    <path d="M2 2h2v2H2z" />
                    <path d="M6 0v6H0V0zM5 1H1v4h4zM4 12H2v2h2z" />
                    <path d="M6 10v6H0v-6zm-5 1v4h4v-4zm11-9h2v2h-2z" />
                    <path d="M10 0v6h6V0zm5 1v4h-4V1zM8 1V0h1v2H8v2H7V1zm0 5V4h1v2zM6 8V7h1V6h1v2h1V7h5v1h-4v1H7V8zm0 0v1H2V8H1v1H0V7h3v1zm10 1h-1V7h1zm-1 0h-1v2h2v-1h-1zm-4 0h2v1h-1v1h-1zm2 3v-1h-1v1h-1v1H9v1h3v-2zm0 0h3v1h-2v1h-1zm-4-1v1h1v-2H7v1z" />
                    <path d="M7 12h1v3h4v1H7zm9 2v2h-3v-1h2v-1z" />
                  </svg>
                </IconButton>
              }
              content={() => componentRef.current}
            />
            <ComponentToPrint ref={componentRef} value={item.id} orderId={item.order?.order_number} title={item.title} /> */}

            {item.shipping_status?.split(',')?.includes('dispatched') ?
              <div className='badge badge-dark' style={{ marginRight: '5px' }}>procured</div> :
              <Button
                variant='outlined'
                disabled={updatestatesresults?.isLoading && updatestatesresults?.originalArgs?.id === item.id}
                onClick={(() => updatestates({
                  domain: item.order?.domain,
                  id: item.id,
                  state: 'dispatched',
                  email: item.order?.users,
                  local: true,
                  userId: userId,
                  userToken: userToken,
                  admin: admin,
                  info: 'local'
                }))}
                style={{
                  textTransform: 'capitalize',
                  fontSize: '10px',
                  fontWeight: 400,
                  minHeight: '15px',
                  padding: '0px 6px',
                  marginRight: '5px',
                  borderRadius: '4px'
                }}>

                <span>
                  {updatestatesresults?.isLoading && (updatestatesresults?.originalArgs?.info === 'local' && updatestatesresults?.originalArgs?.id === item.id) ?
                    <CircularProgress style={{ fontSize: '12px', width: '8px', height: '8px', color: '#fff' }} />
                    :
                    <>
                      Procure
                    </>
                  }
                </span>
              </Button>
            }

            {isDelayedStatus(item.shipping_status, item.state) &&
              <>
                {isDelayed(item.createdAt, item.estimated_delivery) &&
                  <span className='badge badge-warning' style={{ marginRight: '5px' }}>Delayed</span>
                }
              </>
            }

            {getMessageCount(item.order?.messages, item.order?.message) &&
              <div style={{ position: 'relative', marginRight: '5px' }}>
                <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" className="bi bi-chat-square-dots" viewBox="0 0 16 16">
                  <path d="M14 1a1 1 0 0 1 1 1v8a1 1 0 0 1-1 1h-2.5a2 2 0 0 0-1.6.8L8 14.333 6.1 11.8a2 2 0 0 0-1.6-.8H2a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1zM2 0a2 2 0 0 0-2 2v8a2 2 0 0 0 2 2h2.5a1 1 0 0 1 .8.4l1.9 2.533a1 1 0 0 0 1.6 0l1.9-2.533a1 1 0 0 1 .8-.4H14a2 2 0 0 0 2-2V2a2 2 0 0 0-2-2z" />
                  <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0" />
                </svg>
                <span
                  className='badge badge-success'
                  style={{
                    position: 'absolute',
                    top: '-7px',
                    right: '-5px',
                    lineHeight: '12px',
                    borderRadius: '100px',
                    width: '12px',
                    height: '12px',
                    textAlign: 'center',
                    fontSize: '10px',
                    fontWeight: 400,
                    padding: '0px'
                  }}
                >{item.order?.messages ? Number(item.order?.messages?.length) + Number(item.order?.message?.length) : item.order?.message?.length}</span>
              </div>
            }
          </div>
        </td>
        <td>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Link style={{ color: !item.order?.status ? 'tomato' : '#5a5858' }} to={`/order/${item.order?.order_number}`}>
              {item.order?.order_number}
            </Link>
            {item.awb_number && <img title='AWB number generated' src='/awb-new.jpeg' style={{ width: '30px', margin: '0px 6px' }} />}
          </div>
        </td>
        <td style={{ textAlign: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {getPaymentTypeIcon(item.order?.payment_type)}
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Link title={item.inventory ? 'Available in inventory' : ''} className="orders-page-item-link" to={`/order/${item.order?.order_number}`}
                style={{ margin: '0 5px', border: `1px solid ${borderColor(item.order?.shipping_status)}`, background: '#fff', padding: '3px', borderRadius: '4px', position: 'relative', zIndex: 111 }}>
                <img src={`${item.image}`} style={{ width: '35px', height: '35px', objectFit: 'contain' }} />
                <span
                  style={{
                    position: 'absolute',
                    top: '-7px',
                    right: '-7px',
                    lineHeight: '15px',
                    background: '#fe7f4a',
                    borderRadius: '100px',
                    width: '15px',
                    height: '15px',
                    textAlign: 'center',
                    fontSize: '12px',
                    color: '#fff',
                    fontWeight: 400,
                  }}
                >{item.quantity}</span>
                {item.inventory ?
                  <span style={{
                    position: 'absolute',
                    bottom: '-7px',
                    right: '-7px',
                  }}><WarehouseOutlinedIcon style={{ fontSize: '15px', color: '#000' }} /></span> : ''
                }
              </Link>
            </div>
          </div>
        </td>
        <td>
          <Link to={`/order/${item.order?.order_number}`} style={{ color: '#5a5858' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <img
                loading="lazy"
                width="20"
                src={`https://flagcdn.com/w20/${item.country?.toLowerCase()}.png`}
                srcSet={`https://flagcdn.com/w40/${item.country?.toLowerCase()}.png 2x`}
                alt=""
              />
              <span className='Ip-title' style={{ margin: '0px 5px', color: !item.order?.status && 'tomato' }}> {item.title}</span>
            </div>
          </Link>
        </td>

        <td>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {item.order?.status ?
              <div>
                {item.currency}  {Number(item.price)}
              </div>
              :
              <span
                className="alert alert-danger boot-alert-style">
                {item.currency}  {Number(item.price)}
              </span>
            }
          </div>
        </td>
        <td>
          <div className="dFlex center nowrap jcr">
            {item.order?.checkout === 'failed' ? <span className='badge badge-danger'>Payment Failed</span> :
              <>
                {item.order?.states === 'cancelled' &&
                  <span className="badge badge-danger">
                    {item.order?.states}
                  </span>
                }
                {item.order?.states !== 'cancelled' &&
                  <>
                    {item.state === 'cancelled' &&
                      <span className="badge badge-danger">
                        {item.state}
                      </span>
                    }
                    {item.state !== 'cancelled' &&
                      <span
                        className={shippingStatusC(item.shipping_state)?.color}>
                        {shippingStatusC(item.shipping_state)?.name}
                      </span>
                    }
                  </>
                }
              </>
            }
            <div style={{ margin: '0px 5px' }}>
              <span
                className="alert boot-alert-style" style={{ color: !item.order?.status && 'tomato' }}>
                {item.order?.country}
              </span>
            </div>
          </div>
        </td>
        <td>
          <div style={{ position: 'relative', zIndex: 111, color: !item.order?.status && 'tomato' }}>{item.order?.domain}</div>
        </td>
        <td className="sticky-column">
          <div className="dFlex center nowrap jcr" style={{ justifyContent: 'right', color: !item.order?.status && 'tomato' }}>
            {moment(item?.createdAt).format('llll')}
          </div>

        </td>
      </tr>
    );
  };


  return (
    <>
      {ordersData?.entities?.undefined?.order_items?.map(function (item, index) {
        return <ComponentToPrintWrapper key={index} item={item} />;
      })}
    </>
  );
}

export default OrderItemsList;
